
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as loginwro5JqY_h5KW7eQAaFtLac9N8BWQRX9jADT3dnAvGxgMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as confirmCX8AQHrZvuFIo34941xBs6fR63WKUcwjkT0f6WM7dW4Meta } from "/opt/buildhome/repo/pages/auth/confirm.vue?macro=true";
import { default as recoverVYde9C_e6sV9_qP6g85l9SPfcKLwQjlaLJEj_WLDwv0Meta } from "/opt/buildhome/repo/pages/auth/recover.vue?macro=true";
import { default as register5lTphtFRNlFlSjuk4VxUGViZtUArcbdveTqy_Rl6yR8Meta } from "/opt/buildhome/repo/pages/auth/register.vue?macro=true";
import { default as strongPassworda3SFk07oFtoKYBoMlevKz4UAWoGE4G33SO53SsECJYYMeta } from "/opt/buildhome/repo/pages/auth/strongPassword.ts?macro=true";
import { default as forgot_45passwordioOe1ufgzy0WtUzM0b2zzf2yOkTqOjLSGnCYiaXaYXIMeta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as _91invitation_id_93TFEf52WVk_45T3zpsenF50sEJHqb0lqBo1PLgtREBuo3YMeta } from "/opt/buildhome/repo/pages/auth/join/[invitation_id].vue?macro=true";
import { default as auth7mkrNdHS1yOEqKXCBLOxFS1_pVXBuAG56CPJ2QwZ_6sMeta } from "/opt/buildhome/repo/pages/auth.vue?macro=true";
import { default as emailsVTOJ6Yc_45JPBJhS3cHyolrdOjO5Qw4MaqkrM3o5uj_ToMeta } from "/opt/buildhome/repo/pages/index/index/emails.vue?macro=true";
import { default as ordersJFVvaZFpUSYQLkKTm0oYtWs5t5j_aNzL8Er1hnRW4tIMeta } from "/opt/buildhome/repo/pages/index/index/orders.vue?macro=true";
import { default as accountDEEh2zaOmvRQAdJEa5NaR64wvrv6_unoGugpA25akoQMeta } from "/opt/buildhome/repo/pages/index/index/account.vue?macro=true";
import { default as seatmapZxbZW2en1isQP2cXgtaDLJdZju7_4511qZpOnU66qHV2gMeta } from "/opt/buildhome/repo/pages/index/index/seatmap.vue?macro=true";
import { default as alli4KbQ3iB37NojPWuWtSr8gT9fGWo1fAmEMI7prba7FwMeta } from "/opt/buildhome/repo/pages/index/index/scanners/all.vue?macro=true";
import { default as profilescdW1CAydVtMxDIhE04IRiIdz2nd3j7srTliRRwrs4kIMeta } from "/opt/buildhome/repo/pages/index/index/scanners/profiles.vue?macro=true";
import { default as scanners6kst0E7flyl79TlYDuFY8l963Epem6yIHFiQa475REAMeta } from "/opt/buildhome/repo/pages/index/index/scanners.vue?macro=true";
import { default as themetUvaN9QcXCX7j8T7XaTItA4pWwqSrgyFzXcqUx8F14MMeta } from "/opt/buildhome/repo/pages/index/index/settings/theme.vue?macro=true";
import { default as usersI9x_45LNoTvlEf_EA2sPJZ77aqJ2ayizgk1vgfADaM_45U0Meta } from "/opt/buildhome/repo/pages/index/index/settings/users.vue?macro=true";
import { default as ticketsfFjQFQ_KOO_45_jbEiAOdEFxbbnpbDlKclQOqrMRLax8gMeta } from "/opt/buildhome/repo/pages/index/index/settings/tickets.vue?macro=true";
import { default as paymentspnQmRp_dQJT8o_j5taTOMEgRKtiqK0Hasj6zhWJFM7cMeta } from "/opt/buildhome/repo/pages/index/index/settings/payments.vue?macro=true";
import { default as organizationFu4XjzRg8vggDCQtTYmCfcIqZyfiAiLzNvIDbhGMdY8Meta } from "/opt/buildhome/repo/pages/index/index/settings/organization.vue?macro=true";
import { default as settingsyOTBzA1CgyfV9xK4a0XEPD4cDXd3lj5GmNMhv6pvPn4Meta } from "/opt/buildhome/repo/pages/index/index/settings.vue?macro=true";
import { default as index5B1PHhSTD362xi7_45qs7EX5euT1j6IIYkELwEkJ79IBYMeta } from "/opt/buildhome/repo/pages/index/index/visitors/orders/index.vue?macro=true";
import { default as _91order_id_93cBiSrl4egVxHha_45v0nRuHouq5NQv1mMTJmHnLmIrOI4Meta } from "/opt/buildhome/repo/pages/index/index/visitors/orders/[order_id].vue?macro=true";
import { default as visitorshAi8JDMrRgYdQRdQseTd43rSOmLx9w7JDQGlTqbivO0Meta } from "/opt/buildhome/repo/pages/index/index/visitors.vue?macro=true";
import { default as clearingsJUR6wtlsCliWrnuDaucFVorrvezU_45gc3m1rz3L1VkWkMeta } from "/opt/buildhome/repo/pages/index/index/clearings.vue?macro=true";
import { default as dashboard64eiy_45dDY2Si4itF1nO3BL53SotNyMXJBfCHcY4pQx4Meta } from "/opt/buildhome/repo/pages/index/index/dashboard.vue?macro=true";
import { default as newxnl9ETyCumg_GUgUSuAIY8te6NVatAANNduTduY8NBAMeta } from "/opt/buildhome/repo/pages/index/index/events/new.vue?macro=true";
import { default as indexo83XGS6fmbhuyM6ECiOSaJOBq_gNDd_WDCVf7Rf0KLUMeta } from "/opt/buildhome/repo/pages/index/index/index/index.vue?macro=true";
import { default as archiverVONcN4ECkgL1RmLzCduDttKWSzJ2NjKXRTeo5r5Tb4Meta } from "/opt/buildhome/repo/pages/index/index/events/index/archive.vue?macro=true";
import { default as index91ZDdxue_LFbRQ_45BM__45tmjll7tFfa_SGYZZN603WcSgMeta } from "/opt/buildhome/repo/pages/index/index/events/index.vue?macro=true";
import { default as indexiN9TFlbY9GB8bqhuhrvmpCVJS70sr6PTzeQawfcSq2gMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/index.vue?macro=true";
import { default as seatsRDcUVSOV3K6pK4NTnYYJUyn2XYD1lqSXwQx_zQxoxbwMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/seats.vue?macro=true";
import { default as overviewtc_9mAnXAmMipFRMfznb0W2f9StAlCeYHqSohJrD4fYMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/overview.vue?macro=true";
import { default as moreLCOj7iHYtk_45RtA3gTwGjdIInWoc6NGqZ9zeXcuD9dLEMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/more.vue?macro=true";
import { default as themeoDGsTVdDK6ZkOjd2nJT4kYoBhTRab_VhyjxBBYOLACsMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/theme.vue?macro=true";
import { default as basicsJDNaYHC5OIfmNvSKS80Tz9BeIvCXTROSPUpkFLMLfFAMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/basics.vue?macro=true";
import { default as metadatabr4qw9c2faTybWFNbKbYv1fvkRoBVUG4qR7uL3pZXx8Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/metadata.vue?macro=true";
import { default as ticket_45types81iVF_JUZGuhJKH0_aN3GhL2v3zS1fDbjBl7gPXdxoQMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/ticket-types.vue?macro=true";
import { default as settings0kUlxHOx6tfPOgE_45yYbjLf_45_453w2Z85oGdZL_45Xi3YFdIMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/settings.vue?macro=true";
import { default as add_45questsPcdHxXSrFELXFVXtFcI__bZQb3kMF2u_d3yjXoNUsrEMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/add-quests.vue?macro=true";
import { default as indexEk_45lGtgwXXcVyStEwiKPbMAskw470f6fL3ymYe003EoMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda/index.vue?macro=true";
import { default as indexA47f9S1ut7QTTmhxEcfOIFDvckUPcJa8zflkpKrYCqMMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/index.vue?macro=true";
import { default as ticket_45designuys5YzK5YGGdKb0HQ3fgd8UtSvZnzUZLqMyMLU6f69MMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/ticket-design.vue?macro=true";
import { default as ticketsZvh2ZBZYHjsHkda7zp3g8xhC21qgVJdBV1znd7Ds_45_450Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/tickets.vue?macro=true";
import { default as indexy0TOToSxSftD3iDzDmfEywfRu_45BaRk3fHtRkAZVm2qcMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/index.vue?macro=true";
import { default as timeslots1THtYVIIJ0DySt_B0dtmGe1jxaTEe3CUk3S_sM_45_ln0Meta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda/timeslots.vue?macro=true";
import { default as _91order_id_93PXynpX6fsNsEaMOnLtwYFTnfFa7EdXF2am6pz2KaZnQMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/[order_id].vue?macro=true";
import { default as _91discount_id_932d1ixgBt2f9lNEhoNavd_oUZpnasQoXrc0Fam5l_dLsMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/[discount_id].vue?macro=true";
import { default as _91event_id_93hCLzqCQREKw9FG_LnPtTG0Zwhddwj9Te2uMnDkYWpDoMeta } from "/opt/buildhome/repo/pages/index/index/events/[event_id].vue?macro=true";
import { default as returnksA_45kRE9LpMVqN4ofrURuSLb0seGj0yESQL1u1T5gKQMeta } from "/opt/buildhome/repo/pages/index/index/onboarding/return.vue?macro=true";
import { default as _91id_93axaZpWav89r3k8r7lQr8W96ys6fplZTsV5yP_ZHO5OMMeta } from "/opt/buildhome/repo/pages/index/index/transactions/[id].vue?macro=true";
import { default as refreshwUSRDVpfjd7oZjbuayonN7Pe41nQN_dzknHdLeO2ddcMeta } from "/opt/buildhome/repo/pages/index/index/onboarding/refresh.vue?macro=true";
import { default as indexRIi9eWCYTv5dd_3yCzAykdM4gsmqtXr1HZNQBTbhlPMMeta } from "/opt/buildhome/repo/pages/index/index/transactions/index.vue?macro=true";
import { default as manage8kCJ9dx7a0NPmZA9wqY8grHedAFgVr99mFlJwPK0EG0Meta } from "/opt/buildhome/repo/pages/index/index/organization/manage.vue?macro=true";
import { default as refundsUtHmU9iYZBG3Iu1X6uqUu5yQohoSc8kS26jeJv_1i0AMeta } from "/opt/buildhome/repo/pages/index/index/transactions/refunds.vue?macro=true";
import { default as defaultTemplate7bayhYH4tvHjlxDgtgFVgMo7WwCwdYywIa9p_P3pY4kMeta } from "/opt/buildhome/repo/pages/index/index/designer/defaultTemplate.ts?macro=true";
import { default as _91_91ticket_design_id_93_938HvBoJraTkdyktD8zE56OSoKm8GpMR2WT8rhOCqfsjcMeta } from "/opt/buildhome/repo/pages/index/index/designer/[[ticket_design_id]].vue?macro=true";
import { default as defaultTemplatezdvMh1KzkTJeIcnPpJ6m7dIcAVrYBXVrKSAeTe9JgKUMeta } from "/opt/buildhome/repo/pages/index/index/report-designer/defaultTemplate.ts?macro=true";
import { default as _91_91ticket_design_id_93_937Nrs1dlnDi7Zg78aJiXm0FinSxvNtLv7tZy9gHR_W3cMeta } from "/opt/buildhome/repo/pages/index/index/report-designer/[[ticket_design_id]].vue?macro=true";
import { default as indexioey6myJFVdIhRKbLDSdEfG7fVHgV0_LYfpnSZSP__45AMeta } from "/opt/buildhome/repo/pages/index/index.vue?macro=true";
import { default as new_45organizationhFjsKwnSE8eKkt9gXbq_zot6PZepI7RpgnBYgE_45G6ygMeta } from "/opt/buildhome/repo/pages/index/new-organization.vue?macro=true";
import { default as authorizeTv_45TyFZ1qnAg81CrAANEUSIgK7VGbLRTJGDKCje_459lwMeta } from "/opt/buildhome/repo/pages/index/oauth2/authorize.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as unsupported_45browser6OncBPr_45D1XREl7BgSrRKuE9n1YXxShldMBwfyTe0HcMeta } from "/opt/buildhome/repo/pages/unsupported-browser.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/pages/auth.vue"),
    children: [
  {
    name: "auth-login",
    path: "login",
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue")
  },
  {
    name: "auth-confirm",
    path: "confirm",
    component: () => import("/opt/buildhome/repo/pages/auth/confirm.vue")
  },
  {
    name: "auth-recover",
    path: "recover",
    component: () => import("/opt/buildhome/repo/pages/auth/recover.vue")
  },
  {
    name: "auth-register",
    path: "register",
    component: () => import("/opt/buildhome/repo/pages/auth/register.vue")
  },
  {
    name: "auth-strongPassword",
    path: "strongPassword",
    component: () => import("/opt/buildhome/repo/pages/auth/strongPassword.ts")
  },
  {
    name: "auth-forgot-password",
    path: "forgot-password",
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-join-invitation_id",
    path: "join/:invitation_id()",
    component: () => import("/opt/buildhome/repo/pages/auth/join/[invitation_id].vue")
  }
]
  },
  {
    name: indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta?.name,
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue"),
    children: [
  {
    name: indexioey6myJFVdIhRKbLDSdEfG7fVHgV0_LYfpnSZSP__45AMeta?.name,
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index.vue"),
    children: [
  {
    name: "index-index-emails",
    path: "emails",
    component: () => import("/opt/buildhome/repo/pages/index/index/emails.vue")
  },
  {
    name: "index-index-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/orders.vue")
  },
  {
    name: "index-index-account",
    path: "account",
    component: () => import("/opt/buildhome/repo/pages/index/index/account.vue")
  },
  {
    name: "index-index-seatmap",
    path: "seatmap",
    component: () => import("/opt/buildhome/repo/pages/index/index/seatmap.vue")
  },
  {
    name: "index-index-scanners",
    path: "scanners",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners.vue"),
    children: [
  {
    name: "index-index-scanners-all",
    path: "all",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners/all.vue")
  },
  {
    name: "index-index-scanners-profiles",
    path: "profiles",
    component: () => import("/opt/buildhome/repo/pages/index/index/scanners/profiles.vue")
  }
]
  },
  {
    name: "index-index-settings",
    path: "settings",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings.vue"),
    children: [
  {
    name: "index-index-settings-theme",
    path: "theme",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/theme.vue")
  },
  {
    name: "index-index-settings-users",
    path: "users",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/users.vue")
  },
  {
    name: "index-index-settings-tickets",
    path: "tickets",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/tickets.vue")
  },
  {
    name: "index-index-settings-payments",
    path: "payments",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/payments.vue")
  },
  {
    name: "index-index-settings-organization",
    path: "organization",
    component: () => import("/opt/buildhome/repo/pages/index/index/settings/organization.vue")
  }
]
  },
  {
    name: "index-index-visitors",
    path: "visitors",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors.vue"),
    children: [
  {
    name: "index-index-visitors-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors/orders/index.vue")
  },
  {
    name: "index-index-visitors-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/visitors/orders/[order_id].vue")
  }
]
  },
  {
    name: "index-index-clearings",
    path: "clearings",
    component: () => import("/opt/buildhome/repo/pages/index/index/clearings.vue")
  },
  {
    name: "index-index-dashboard",
    path: "dashboard",
    component: () => import("/opt/buildhome/repo/pages/index/index/dashboard.vue")
  },
  {
    name: "index-index-events-new",
    path: "events/new",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/new.vue")
  },
  {
    name: "index-index-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index/index/index.vue")
  },
  {
    name: "index-index-events",
    path: "events",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/index.vue"),
    children: [
  {
    name: "index-index-events-index-archive",
    path: "archive",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/index/archive.vue")
  }
]
  },
  {
    name: _91event_id_93hCLzqCQREKw9FG_LnPtTG0Zwhddwj9Te2uMnDkYWpDoMeta?.name,
    path: "events/:event_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id].vue"),
    children: [
  {
    name: "index-index-events-event_id",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/index.vue")
  },
  {
    name: "index-index-events-event_id-seats",
    path: "seats",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/seats.vue")
  },
  {
    name: "index-index-events-event_id-overview",
    path: "overview",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/overview.vue")
  },
  {
    name: "index-index-events-event_id-settings",
    path: "settings",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings.vue"),
    children: [
  {
    name: "index-index-events-event_id-settings-more",
    path: "more",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/more.vue")
  },
  {
    name: "index-index-events-event_id-settings-theme",
    path: "theme",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/theme.vue")
  },
  {
    name: "index-index-events-event_id-settings-basics",
    path: "basics",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/basics.vue")
  },
  {
    name: "index-index-events-event_id-settings-metadata",
    path: "metadata",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/metadata.vue")
  },
  {
    name: "index-index-events-event_id-settings-ticket-types",
    path: "ticket-types",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/settings/ticket-types.vue")
  }
]
  },
  {
    name: "index-index-events-event_id-add-quests",
    path: "add-quests",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/add-quests.vue")
  },
  {
    name: "index-index-events-event_id-agenda",
    path: "agenda",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda/index.vue")
  },
  {
    name: "index-index-events-event_id-orders",
    path: "orders",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/index.vue")
  },
  {
    name: "index-index-events-event_id-ticket-design",
    path: "ticket-design",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/ticket-design.vue")
  },
  {
    name: "index-index-events-event_id-orders-tickets",
    path: "orders/tickets",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/tickets.vue")
  },
  {
    name: "index-index-events-event_id-discounts",
    path: "discounts",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/index.vue")
  },
  {
    name: "index-index-events-event_id-agenda-timeslots",
    path: "agenda/timeslots",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/agenda/timeslots.vue")
  },
  {
    name: "index-index-events-event_id-orders-order_id",
    path: "orders/:order_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/orders/[order_id].vue")
  },
  {
    name: "index-index-events-event_id-discounts-discount_id",
    path: "discounts/:discount_id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/events/[event_id]/discounts/[discount_id].vue")
  }
]
  },
  {
    name: "index-index-onboarding-return",
    path: "onboarding/return",
    component: () => import("/opt/buildhome/repo/pages/index/index/onboarding/return.vue")
  },
  {
    name: "index-index-transactions-id",
    path: "transactions/:id()",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/[id].vue")
  },
  {
    name: "index-index-onboarding-refresh",
    path: "onboarding/refresh",
    component: () => import("/opt/buildhome/repo/pages/index/index/onboarding/refresh.vue")
  },
  {
    name: "index-index-transactions",
    path: "transactions",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/index.vue")
  },
  {
    name: "index-index-organization-manage",
    path: "organization/manage",
    component: () => import("/opt/buildhome/repo/pages/index/index/organization/manage.vue")
  },
  {
    name: "index-index-transactions-refunds",
    path: "transactions/refunds",
    component: () => import("/opt/buildhome/repo/pages/index/index/transactions/refunds.vue")
  },
  {
    name: "index-index-designer-defaultTemplate",
    path: "designer/defaultTemplate",
    component: () => import("/opt/buildhome/repo/pages/index/index/designer/defaultTemplate.ts")
  },
  {
    name: "index-index-designer-ticket_design_id",
    path: "designer/:ticket_design_id?",
    component: () => import("/opt/buildhome/repo/pages/index/index/designer/[[ticket_design_id]].vue")
  },
  {
    name: "index-index-report-designer-defaultTemplate",
    path: "report-designer/defaultTemplate",
    component: () => import("/opt/buildhome/repo/pages/index/index/report-designer/defaultTemplate.ts")
  },
  {
    name: "index-index-report-designer-ticket_design_id",
    path: "report-designer/:ticket_design_id?",
    component: () => import("/opt/buildhome/repo/pages/index/index/report-designer/[[ticket_design_id]].vue")
  }
]
  },
  {
    name: "index-new-organization",
    path: "new-organization",
    component: () => import("/opt/buildhome/repo/pages/index/new-organization.vue")
  },
  {
    name: "index-oauth2-authorize",
    path: "oauth2/authorize",
    component: () => import("/opt/buildhome/repo/pages/index/oauth2/authorize.vue")
  }
]
  },
  {
    name: "unsupported-browser",
    path: "/unsupported-browser",
    component: () => import("/opt/buildhome/repo/pages/unsupported-browser.vue")
  }
]